import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface ICalendarCellWrapper {
  isPast?: boolean;
  isActive?: boolean;
  isSelected: boolean;
}

interface ICalendarCell extends ICalendarCellWrapper {
  index: number;
  weekday: string;
  date: string;
  getCurrentDate: (date: string, index: number) => void;
}

export const CalendarCell = (
  { isActive, weekday, date, getCurrentDate, isPast, index, isSelected } : ICalendarCell
) => {
  const cellClick = () => (!isPast && isActive) && getCurrentDate(date, index);
  return (
    <CalendarCellWrapper onClick={cellClick} isActive={isActive} isPast={isPast} isSelected={isSelected} >
      <Weekday>{weekday}</Weekday>
      <DateComponent isSelected={isSelected}>{date}</DateComponent>
    </CalendarCellWrapper>
  )
}

const CalendarCellWrapper = styled.div<ICalendarCellWrapper>`
  opacity: ${({isPast}) => isPast && 0.2};
  width: 14.285%;
  font-size: 20px;
  padding: 12px;
  color: ${({isSelected}) => isSelected ? `${colors.white} !important` : colors.lightBlack };
  cursor: ${({isPast}) => !isPast && 'pointer' };
  box-sizing: border-box;
  background: ${({isSelected}) => isSelected ? `${colors.lightBlue} !important` : colors.white };
  height: 100px;
  border: solid 1px ${colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    background: ${({isPast}) => !isPast && colors.lightGray };
  }
  @media (max-width: 500px) {
    padding: 12px 5px;
  }
`;

const Weekday = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;

const DateComponent = styled.p<ICalendarCellWrapper>`
  margin: 0;
  font-size: 16px;
  color: ${({isSelected}) => isSelected ? `${colors.white} !important` : colors.lightBlack };
  @media (max-width: 700px) {
    padding: 12px 0;
    word-wrap: break-word;
    width: 44px;
    font-size: 14px;
  }
`;
