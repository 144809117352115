import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IInput {
  placeholder: string;
  value: string;
  name: string;
  onChange: (value: string, name: string) => void;
};

export class Input extends React.Component<IInput> {
  public onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    return this.props.onChange(value, name);
  };

  public render() {
    const { placeholder, value, name } = this.props;

    return (
      <InputStyled
        placeholder={placeholder}
        onChange={this.onChangeInput}
        value={value}
        name={name}
      />
    )
  }
}

const InputStyled = styled.input`
  width: 100%;
  color: ${colors.lightBlack};
  padding-left: 30px;
  height: 55px;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  border: none;
  font-size: 14px;
  ::-webkit-input-placeholder {color: ${colors.silver}}
  ::-moz-placeholder          {color: ${colors.silver}}
  :-moz-placeholder           {color: ${colors.silver}}
  :-ms-input-placeholder      {color: ${colors.silver}}
`;
