import React, {Fragment} from 'react';
import styled from 'styled-components';

import { CalendarCell } from 'atoms';
import { TimeField } from 'molecules';
import { ICalendar } from 'interfaces';

export const Calendar = (
  { getCurrentDate, calendarData, chooseTime, dateIndex: {index, isEnabled}, timeList, activeDateIndex, selectedTime }: ICalendar
) => {
  const isInteger = Boolean(index / 7);
  const tailIntegerIndex = (Math.floor(index / 7) + 1) * 7;
  const tailFractionalIndex = (Math.floor((index + 1) / 7) + 1) * 7;

  const getIndex = () => {
    return isInteger ?
      (tailIntegerIndex === calendarData.length ? tailIntegerIndex - 1: tailIntegerIndex) :
      (tailFractionalIndex === calendarData.length ? tailIntegerIndex - 1: tailFractionalIndex);
  }
  return (
    <Wrapper>
    {
      calendarData.map(({date, isActive, weekday, isPast}, index) => {
        const result = activeDateIndex === index;
        if(isEnabled) {
          
          return (
            index === getIndex() ? 
            <Fragment key={index}>
              {
                tailIntegerIndex !== calendarData.length &&
                 <TimeField chooseTime={chooseTime} timeList={timeList} selectedTime={selectedTime} />
              }
              <CalendarCell
                isActive={isActive}
                weekday={weekday}
                date={date}
                getCurrentDate={getCurrentDate}
                isPast={isPast}
                index={index}
                isSelected={result}
              />
              {
                tailIntegerIndex === calendarData.length &&
                 <TimeField chooseTime={chooseTime} timeList={timeList} selectedTime={selectedTime} />
              }
            </Fragment> : 
            <CalendarCell
              key={index}
              isActive={isActive}
              weekday={weekday}
              date={date}
              getCurrentDate={getCurrentDate}
              isPast={isPast}
              index={index}
              isSelected={result}
            />
          )

        } else {
          return (
            <CalendarCell
              key={index}
              isActive={isActive}
              weekday={weekday}
              date={date}
              getCurrentDate={getCurrentDate}
              isPast={isPast}
              index={index}
              isSelected={result}
            />
          )
        }

      })
    }
  </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 150px;
`;
