import axios from 'axios';

import { ISendFormData } from 'interfaces';

class AppApi {
  private apiUrl = 'https://online.shinaminsk.by/';
  
  public async getStations() {
    const url: string = `${this.apiUrl}get_addresses`;

    try {
      return await axios.get(url);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public async getBusyTime(id: number) {
    const url: string = `${this.apiUrl}busy/${id}/`;
    try {
      return await axios.get(url);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public async postUserData(body: ISendFormData) {
    const url: string = `${this.apiUrl}order`;

    try {
      return await axios.post(url, body);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export const Api = new AppApi();
