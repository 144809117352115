import React from 'react';
import styled from 'styled-components';

import { Button } from 'atoms';
import { IMonthList } from 'interfaces';

export const MonthList = (
  { monthList, chooseMonth }: IMonthList
) => (
  <Wrapper>
    {
      monthList.map(({monthName, isActive}, i) => 
        <ButtonWrapper key={monthName}>
          <Button
            width='110px'
            height='43px'
            isActive={isActive}
            title={monthName}
            onClick={() => chooseMonth(i)}
            isShow={true}
          />
        </ButtonWrapper>
      )
    }
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  margin: 40px 0;
`;

const ButtonWrapper = styled.div`
  margin-right: 5px;
`;
