import React from 'react';
import styled from 'styled-components';

import { TimeCell } from 'atoms';
import { colors } from 'context';
interface ITimeCell {
  time: string;
  isVacant: boolean;
}

interface ITimeField {
  selectedTime: string;
  timeList: ITimeCell[];
  chooseTime: (time: string) => void;
}

export const TimeField = (
  { timeList, chooseTime, selectedTime }: ITimeField
) => (
  <Wrapper>
    {
      timeList.map(({time, isVacant}) =>
        <TimeCell
          key={time}
          value={time}
          isVacant={isVacant}
          choiceTime={chooseTime}
          isSelected={selectedTime === time}
        />)
    }
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${colors.lightSilver};
`;
