import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

export interface IButtonWrapper {
  width: string;
  height: string;
  isActive?: boolean;
  isShow: boolean;
}

export interface IButton extends IButtonWrapper {
  isShow: boolean;
  title: string;
  onClick: () => void;
}

export const Button = (
  { width, title, height, onClick, isActive, isShow }: IButton
) => (
  <ButtonWrapper
    width={width}
    height={height}
    onClick={onClick}
    isActive={isActive}
    isShow={isShow}
  > { title } </ButtonWrapper>
)

const ButtonWrapper = styled.button<IButtonWrapper>`
  outline:none;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ isActive }) => isActive ? `${colors.lightBlue}` : 'none'};
  color: ${({ isActive }) => isActive ? `${colors.white}` : `${colors.lightBlack}`};
  display: ${({ isShow }) => isShow ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  &:hover {
    background: ${colors.blue};
  }
`;
