

export const timeDataGenerator = (start: string, end: string) => {
  const timeData: any = [];

  while(start !== end) {
    const [hour, minutes] = start.split(':');
    const checkHour = () => Number(hour) < 9 ? `0${(Number(hour) + 1)}` : Number(hour) + 1;

    timeData.push(
      {
        time: start,
        isVacant: true,
      }
    )
    start = minutes === '00' ? `${hour}:30` : `${checkHour()}:00`;
  }

  timeData.push(
    {
      time: start,
      isVacant: true,
    }
  );
  return timeData;
};

