import React, { Component } from 'react';

import './App.css';
import { ShinsCalendar } from 'views';

class App extends Component {
  render() {
    return (
      <ShinsCalendar/>
    );
  }
}

export default App;
