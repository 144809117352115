import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IProps {
  isActive: boolean;
  isAfter?: boolean;
  isBefore?: boolean;
}

interface ILevelCircle extends IProps {
  children: number;
  onClick: (activeLevel: number) => void;
}

export const LevelCircle = (
  { children, onClick, isActive, isAfter, isBefore } : ILevelCircle
) => {
  const getLevelCircleNumber = () => onClick(children);
  return (
    <Wrapper>
      <AfterElem
        isActive={isActive}
        isAfter={isAfter}
      />
      <LevelCircleWrapper
        onClick={getLevelCircleNumber}
        isActive={isActive}
      >
      {children}
      </LevelCircleWrapper>
      <BeforeElem
        isActive={isActive}
        isBefore={isBefore}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LevelCircleWrapper = styled.div<IProps>`
  width: 59px;
  height: 59px;
  font-size: 24px;
  background: ${({isActive}) => isActive ? `${colors.lightBlue}` : `${colors.white}`};
  color: ${({isActive}) => isActive ? `${colors.white}` : `${colors.lightGray}`};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AfterElem = styled.div<IProps>`
  display: ${ ({isAfter}) => !isAfter && `none`};
  background: ${({isActive}) => isActive ? `${colors.lightBlue}` : `${colors.white}`};
  height: 2px;
  width: 85px;
  @media (max-width: 768px) {
    width: 30px;
  }
`;

const BeforeElem = styled.div<IProps>`
  display: ${ ({isBefore}) => !isBefore && `none`};
  background: ${({isActive}) => isActive ? `${colors.lightBlue}` : `${colors.white}`};
  height: 2px;
  width: 85px;
  @media (max-width: 768px) {
    width: 30px;
  }
`;
