import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IStationAddressWrapper {
  isActiveStation: boolean;
  width?: string;
}

export interface IStationData {
  address: string;
  timetable: string;
  phone1: string;
  phone: string;
  id: number;
  fulltime: boolean;
  start_time: string;
  end_time: string;
}

interface IStationAddress extends IStationAddressWrapper, IStationData {
  chooseStation: (data: number, address: string, fulltime: boolean, start_time: string, end_time: string) => void;
}

export const StationAddress = (
  { width, address, timetable, phone1, chooseStation, id, isActiveStation, fulltime, start_time, end_time }: IStationAddress
) => {
  const chooseCurrentStation = () => chooseStation(id, address, fulltime, start_time, end_time);

  return (
    <StationAddressWrapper width={width} onClick={chooseCurrentStation} isActiveStation={isActiveStation}>
      <Title>{address}</Title>
      <WorkTime>{timetable}</WorkTime>
      <PhoneNumber href={`tel:${phone1}`}>{phone1}</PhoneNumber>
    </StationAddressWrapper>
  )
}

const StationAddressWrapper = styled.div<IStationAddressWrapper>`
  width: 31%;
  margin: 0 2% 40px 0;
  
  @media only screen and (max-width: 1039px) {
    margin: 0 1% 40px;
  }
  padding: 25px;
  height: 165px;
  background: ${colors.white};
  color: ${colors.lightBlack};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &:hover {
    border: 2px solid ${colors.lightBlue};
  }
  @media (max-width: 1039px) {
    width: 47%;
  }
  @media (max-width: 750px) {
    width: calc(100% - 4px);
  }
  border: ${({ isActiveStation }) => isActiveStation ? `4px solid  ${colors.lightBlue} !important` : `2px solid  ${colors.white}`} ;
  @media only screen and (max-width: 750px) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  text-align: center;
`;

const WorkTime = styled.p`
  font-size: 14px;
`;

const PhoneNumber = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${colors.lightBlack};
`;
