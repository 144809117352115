import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IHeader {
  headerText: string;
  headerCaption: string;
}

export const Header = (
  { headerText, headerCaption } : IHeader
) => (
  <HeaderWrapper>
    <H2>{headerText}</H2>
    <Line/>
    <HeaderText>{headerCaption}</HeaderText>
  </HeaderWrapper>
);

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.oilBlack};
`;

const H2 = styled.h2`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
  @media (max-width: 750px) {
    margin-top: 15px;
  }
`;

const Line = styled.div`
  width: 135px;
  height: 3px;
  background: ${colors.lightBlue};
`;

const HeaderText = styled.p`
  font-size: 18px;
  font-weight: 900;
  text-align: center;
`;
