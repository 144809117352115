import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface ICloseButton {
  onClick: () => void;
}

export const CloseButton = (
  { onClick } : ICloseButton
) => (
  <Wrapper onClick={onClick}>
    <Cross/>
  </Wrapper>
);

const Wrapper = styled.button`
  display: block;
  cursor: pointer;
  background: transparent;
  border: transparent;
  width: 36px;
  height: 25px;
`;

const Cross = styled.span`
  display: block;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 23px;
    height: 2px;
    transition: all .3s;
    transform: rotate(-45deg);
    background: ${colors.oceanBlue};
    box-sizing: border-box;
  }
  &::after {
    content: "";
    position: absolute;
    width: 23px;
    height: 2px;
    transition: all .3s;
    transform: rotate(45deg);
    background: ${colors.oceanBlue};
    box-sizing: border-box;
  }
`;
