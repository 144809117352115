import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IWrapper {
  isSelected: boolean;
  isVacant: boolean;
}

interface ITimeCell extends IWrapper {
  value: string;
  choiceTime: (time: string) => void;
}

export const TimeCell = (
  { value, isVacant, choiceTime, isSelected }: ITimeCell
) => {
  const getTime = () => {
    const time = isVacant ? value : '';
    return choiceTime(time);
  }

  return (
    <Wrapper onClick={getTime} isVacant={isVacant} isSelected={isSelected}>
      {value}
    </Wrapper>
  )
};

const Wrapper = styled.div<IWrapper>`
  width: 102px;
  height: 45px;
  display: flex;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${colors.black};
  overflow: hidden;
  background: ${({ isVacant }) => isVacant ? colors.lightSilver : colors.gray};
  border: ${({ isSelected }) => isSelected && `1px solid ${colors.lightBlue}`};
  box-sizing: border-box;
  cursor: ${({ isVacant }) => isVacant && 'pointer'};
  &:hover {
    border: ${({ isVacant }) => isVacant && `1px solid ${colors.lightBlue}`};
  }
`;
