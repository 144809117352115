import React from 'react';
import styled from 'styled-components';

interface IPopUpDescription {
  title: string;
  caption: string;
}

export const PopUpDescription = (
  { title, caption } : IPopUpDescription
) => (
  <Description>
    <BoldText>
      {title}
    </BoldText>
    {caption}
  </Description>
);

const Description = styled.p`
  font-size: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;
