import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { textConstants } from 'infrastructure';
import { colors } from 'context';
import { Button, Input, Form } from 'atoms';
import { labels } from './constants';
import { ISignUpForm } from 'interfaces';

export const SignUpForm = (
  { nameValue, phoneValue, sendForm, onChange }: ISignUpForm
) => (
  <Form>
    <Title>{textConstants.informationText}</Title>
    <Wrapper>
      <Input
        placeholder={textConstants.enterYourName}
        onChange={onChange}
        value={nameValue}
        name={labels.userName}
      />
    </Wrapper>
    <Wrapper>
      <Mask
        mask="+375 (99) 999-99-99"
        placeholder={textConstants.phone}
        value={phoneValue}
        onChange={(e) => onChange(e.target.value, e.target.name)}
        name={labels.userPhone}
      />
    </Wrapper>
    <Wrapper>
      <Button
        width='100%'
        height='55px'
        title={textConstants.btnSignUp}
        onClick={sendForm}
        isActive={true}
        isShow={true}
      />
    </Wrapper>
  </Form>
);

const Title = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.lightBlack};
  margin-bottom: 35px;
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const Mask = styled(InputMask)`
  width: 100%;
  color: ${colors.lightBlack};
  padding-left: 30px;
  height: 55px;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  border: none;
  font-size: 14px;
  ::-webkit-input-placeholder {color: ${colors.silver}}
  ::-moz-placeholder          {color: ${colors.silver}}
  :-moz-placeholder           {color: ${colors.silver}}
  :-ms-input-placeholder      {color: ${colors.silver}}
`