import React from 'react';
import styled from 'styled-components';

import { colors } from 'context';
import { textConstants, popUpTitles } from 'infrastructure';
import { CloseButton, PopUpDescription } from 'atoms'

interface IPopUpDescriptions {
  date: string;
  time: string;
  name: string;
  phone: string;
  [key: string]: string
}

interface IPopUp {
  recordingData: IPopUpDescriptions;
  address: string;
  onClick: () => void;
};

export const PopUp = (
  { address, recordingData, onClick }: IPopUp
) => {
  const { popUpCongratulation, popUpCaption, popUpAddressTitle } = textConstants;

  return (
    <>
      <PopUpBackground/>

      <PopUpMassage>
          <CloseButtonWrapper>
            <CloseButton onClick={onClick}/>
          </CloseButtonWrapper>
          <CongratulationTitle>
            {popUpCongratulation}
          </CongratulationTitle>
          <Congratulation>
            {popUpCaption}
          </Congratulation>
          <AddressTitle>
            {popUpAddressTitle}
          </AddressTitle>
          <AddressText>
            {address}
          </AddressText>
          {
            Object.keys(popUpTitles).map((key) => 
            <PopUpDescription title={popUpTitles[key]} caption={recordingData[key]} key={key}/>)
          }
      </PopUpMassage>
    </>
  )
};

const PopUpBackground = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.purple};
  opacity: .3;
`;

const PopUpMassage = styled.div`
  color: ${colors.silverBlack};
  width: 400px;
  box-sizing: border-box;
  padding: 70px 65px 75px;
  border-radius: 3px;
  background: ${colors.white};
  position: relative;
  z-index: 15;
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  margin: 20% auto;
  @media (max-width: 500px) {
    margin: 35% auto;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const CongratulationTitle = styled.h2`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 20px;
  text-align: center;
`;

const Congratulation = styled.p`
  font-size: 18px;
  margin-bottom: 50px;
  text-align: center;
`;

const AddressTitle = styled.h3`
  font-size: 16px;
  margin: 0;
  padding-bottom: 5px;
`;

const AddressText = styled.p`
  font-size: 16px; 
  margin: 0 0 30px;
`;
