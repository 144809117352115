import React from 'react';
import styled from 'styled-components';

import { StationAddress } from 'atoms';
import { IStationsList } from 'interfaces';
export const StationsList = (
  { stationsList, chooseStation, activeStation } : IStationsList
) => {
  const isActiveStation = (index: number) => {
    return (
      activeStation - 1 === index
    )
  };
  return (
    <Wrapper>
      {
        stationsList.map(({address, timetable, phone1, phone, id, fulltime, start_time, end_time}, i) => 
          <StationAddress
            address={address}
            timetable={timetable}
            phone1={phone1}
            phone={phone}
            chooseStation={chooseStation}
            id={id}
            key={address}
            isActiveStation={isActiveStation(i)}
            fulltime={fulltime}
            start_time={start_time}
            end_time={end_time}
          /> )
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
