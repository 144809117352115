import { createAction, handleActions, Action } from 'redux-actions';

import { ACTIONS } from 'actionConstants';
import { timeData } from 'infrastructure';
import { IShinsCalendar, ILevelData, IMonth, ICalendarCell, IStationAddress, ITime } from 'interfaces';

export const chooseStation = createAction(ACTIONS.CHOOSE_STATION);
export const changeLevel = createAction(ACTIONS.CHANGE_LEVEL);
export const changeInputValue = createAction(ACTIONS.CHANGE_INPUT_VALUE);
export const getStations = createAction(ACTIONS.GET_STATIONS);
export const saveStationsData = createAction(ACTIONS.SAVE_STATIONS_DATA);
export const getBusyTime = createAction(ACTIONS.GET_BUSY_TIME);
export const saveBusyTime = createAction(ACTIONS.SAVE_BUSY_TIME);
export const saveMonthsList = createAction(ACTIONS.SAVE_MONTHS_LIST);
export const saveCalendarData = createAction(ACTIONS.SAVE_CALENDAR_DATA);
export const chooseMonth = createAction(ACTIONS.CHOOSE_MONTH);
export const getDate = createAction(ACTIONS.GET_DATE);
export const sendForm = createAction(ACTIONS.SEND_FORM);
export const saveDayIndex = createAction(ACTIONS.SAVE_DAY_INDEX);
export const saveTime = createAction(ACTIONS.SAVE_TIME);
export const resetDateIndex = createAction(ACTIONS.RESET_DATE_INDEX);
export const togglePopup = createAction(ACTIONS.TOGGLE_POPUP);
export const resetForm = createAction(ACTIONS.RESET_FORM);
export const resetStationAddress = createAction(ACTIONS.RESET_STATION_ADDRESS);
export const blockSendButton = createAction(ACTIONS.BLOCK_SEND_BUTTON);
export const saveTimeData = createAction(ACTIONS.SEVE_TIME_DATA);

const defaultState = {
    timeData: [...timeData],
    levelConditions: [
    {
      label: 1,
      isActive: true,
    },
    {
      label: 2,
      isActive: false,
    },
    {
      label: 3,
      isActive: false,
    }
  ],
  busyTime: [],
  stationsList: [],
  calendarData: [],
  activeMonth: 0,
  monthList: [],
  isShowPopUp: false,
  dateIndex: {
    isEnabled: false,
    index: -1
  },
  isButtonActive: true,
  userChoice: {
    userName: '',
    userPhone: '',
    date: '',
    time: '',
    address: 1,
    stationAddress: 'СТО / Шиномонтаж: ул. Железнодорожная, 23',
  }
};

export const mainReducer = handleActions<any, any> (
  {
    [ACTIONS.CHANGE_LEVEL]: (
      state: IShinsCalendar,
      { payload }: Action<number> 
    ): IShinsCalendar => {
      return (
      {
        ...state,
        levelConditions: state.levelConditions.map((item: ILevelData) => (
          {
            ...item,
            isActive: item.label === payload})
        )
      }
    )},
    [ACTIONS.CHOOSE_STATION]: (
      state: IShinsCalendar,
      { payload }: Action<{address: string; data: number}>
    ): IShinsCalendar  => {
      if(payload) {
      const { data,  address } = payload;
        return (
          {
            ...state, 
            userChoice: {
              ...state.userChoice,
              address: data,
              stationAddress: address,
            }
          }
        )
      }
      return state;
    },
    [ACTIONS.CHANGE_INPUT_VALUE]: (
      state: IShinsCalendar,
      { payload }: Action<{value: string, name: string}>
    ): IShinsCalendar => {
      if(payload) {
        const { value, name } = payload;
        return {
        ...state,
        userChoice: {
          ...state.userChoice,
          [name]: value
        }
        }
      };
      return state;
    },
    [ACTIONS.SAVE_MONTHS_LIST]: (
      state: IShinsCalendar, { payload }: Action<IMonth[]>
    ): IShinsCalendar => {
      if(payload) {
        return {
          ...state, 
          monthList: payload
        }
      };
      return state;
    },
    [ACTIONS.SAVE_CALENDAR_DATA]: (
      state: IShinsCalendar,
      { payload }: Action<ICalendarCell[][]>
    ): IShinsCalendar => {
      if(payload) {
        return {
          ...state, 
          calendarData: payload
        }
      };
      return state;
    },
    [ACTIONS.SAVE_STATIONS_DATA]: (
      state: IShinsCalendar,
      { payload }: Action<IStationAddress[]>
    ): IShinsCalendar => {
      if(payload) {
        return {
          ...state, 
          stationsList: payload
        }
      };
      return state;
    },
    [ACTIONS.CHOOSE_MONTH]: (
      state: IShinsCalendar, 
      { payload }: Action<number>
    ): IShinsCalendar => {
      if(payload || payload === 0) {
        return {
          ...state, 
          activeMonth: payload,
          monthList: 
            state.monthList.map((item: IMonth, i: number) => (
              {
                ...item,
                isActive: i === payload
              }
            ))
        }
      };
      return state;
    },
      [ACTIONS.GET_DATE]: (
        state: IShinsCalendar,
        { payload }: Action<string>
      ): IShinsCalendar => {
        if(payload) {
          const [ day, month, year ] = payload.split('.');
          return {
            ...state, 
            userChoice: {
              ...state.userChoice,
              date: `${year}-${month}-${day}`
            }
          }
        };
        return state;
      },
      [ACTIONS.SAVE_BUSY_TIME]: (
        state: IShinsCalendar,
        { payload }: Action<{time: string; date: string}[]>
      ): IShinsCalendar => {
        if(payload) {
          return {
            ...state,
            busyTime: payload
          }
        };
        return state;
      },
      [ACTIONS.RESET_FORM]: (
        state: IShinsCalendar
      ): IShinsCalendar => (
        {
          ...state,
          dateIndex: {
            isEnabled: false,
            index: -1
          },
          userChoice: {
            userName: '',
            userPhone: '',
            date: '',
            time: '',
            address: 1,
            stationAddress: 'СТО / Шиномонтаж: ул. Железнодорожная, 23',
          },
        }),
      [ACTIONS.TOGGLE_POPUP]: (
        state: IShinsCalendar,
        { payload }: Action<boolean>
      ) : IShinsCalendar => {
          return {
            ...state,
            isShowPopUp: payload
          }
      },
      [ACTIONS.RESET_DATE_INDEX]: (state: IShinsCalendar) : IShinsCalendar => (
        {
          ...state,
          dateIndex: {
            isEnabled: false,
            index: -1,
          }
        }),
      [ACTIONS.SAVE_TIME]: (
        state: IShinsCalendar,
        { payload }: Action<string>
      ): IShinsCalendar => {
        if(payload) {
          return {
            ...state,
            userChoice: {
              ...state.userChoice,
              time: payload
            }
          }
        };
        return state;
      },
      [ACTIONS.SAVE_DAY_INDEX]: (
        state: IShinsCalendar,
        { payload }: Action<number>
      ): IShinsCalendar => {
        if(payload || payload === 0) {
          return {
            ...state,
            dateIndex: {
              isEnabled: true,
              index: payload
            }
          }
        };
        return state;
      },
      [ACTIONS.RESET_STATION_ADDRESS]: (
        state: IShinsCalendar
      ): IShinsCalendar => (
        {
          ...state,
          userChoice: {
            ...state.userChoice,
            address: 0
          }
        }),
      [ACTIONS.BLOCK_SEND_BUTTON]: (
        state: IShinsCalendar,
        {payload}: Action<boolean>
      ): IShinsCalendar => {
        if(payload || payload === false) {
          return {
            ...state,
            isButtonActive: payload,
          }
        };
        return state;
      },
      [ACTIONS.SEVE_TIME_DATA]: (
        state: IShinsCalendar,
        {payload}: Action<ITime[]>
      ): IShinsCalendar => {
        if(payload) {
          return {
            ...state,
            timeData: payload,
          }
        };
        return state;
      },
  },
  defaultState
);

