import { ICalendarCell } from "interfaces";
import { weekDays } from "infrastructure";

export const calendarDataFormatter = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const calendarData: ICalendarCell[][] = [[], [], []];

  return calendarData.map((month: ICalendarCell[], i) => {
    const firstMonthDay = new Date(currentYear, currentMonth + i).getDay() - 1;
    const countDaysCurrentMonth = new Date(
      currentYear,
      currentMonth + i + 1,
      0
    ).getDate();
    const remainderDays = countDaysCurrentMonth - currentDay + 1;
    const calendarCellCountWithoutTail =
      firstMonthDay + currentDay - 1 + remainderDays;
    const tailEmptyDays =
      calendarCellCountWithoutTail % 7 === 0
        ? 0
        : (Math.floor(calendarCellCountWithoutTail / 7) + 1) * 7 -
          calendarCellCountWithoutTail;

    const emptyDaysPoint = firstMonthDay;
    const pastDaysPoint = firstMonthDay + currentDay - 1;
    const remainderDaysPoint = pastDaysPoint + remainderDays;
    const tailEmptyDaysPoint = remainderDaysPoint + tailEmptyDays;
    const allCalendarCellCount =
      firstMonthDay + currentDay - 1 + remainderDays + tailEmptyDays;

    const getPastData = (index: number) =>
      currentMonth < 9
        ? `${index + 1}.0${currentMonth + i + 1}.${currentYear}`
        : `${index + 1}.${currentMonth + i + 1}.${currentYear}`;

    const getCurrentData = (index: number) =>
      currentMonth < 9
        ? `${currentDay + index}.0${currentMonth + i + 1}.${currentYear}`
        : `${currentDay + index}.${currentMonth + i + 1}.${currentYear}`;

    const calendarCountArr = getArrFromNumber(allCalendarCellCount);

    return calendarCountArr.map((item: number, index: number) => {
      if (index < emptyDaysPoint) {
        return {
          weekday: "",
          isActive: false,
          date: ""
        };
      }
      if (index < pastDaysPoint) {
        return {
          weekday:
            weekDays[
              new Date(
                currentYear,
                currentMonth + i,
                index - emptyDaysPoint
              ).getDay()
            ],
          isActive: true,
          date: getPastData(index - emptyDaysPoint),
          isPast: !Boolean(i)
        };
      }
      if (index < remainderDaysPoint) {
        return {
          weekday:
            weekDays[
              new Date(
                currentYear,
                currentMonth + i,
                currentDay + index - 1 - pastDaysPoint
              ).getDay()
            ],
          isActive: true,
          date: getCurrentData(index - pastDaysPoint)
        };
      }
      if (index < tailEmptyDaysPoint) {
        return {
          weekday: "",
          isActive: false,
          date: ""
        };
      }
      return {
        weekday: "",
        isActive: false,
        date: ""
      };
    });
  });
};

export const getArrFromNumber = (length: number) => {
  return Array(length).fill(1);
};
