import React from 'react';
import styled from 'styled-components';

import { IShinsCalendar, IButtonWrapper, IMonth, ICalendarCell, ISendFormData, ITime } from 'interfaces';
import { Header, Button, Footer } from 'atoms';
import { LevelScale, StationsList, SignUpForm, MonthList, Calendar, PopUp } from 'molecules';
import { colors } from 'context';
import { calendarDataFormatter, getArrFromNumber } from 'helpers';

import { textConstants, monthsList, timeData } from 'infrastructure';
const { headerCaption, headerText } = textConstants;
import { timeDataGenerator } from 'helpers';

interface IProps extends IShinsCalendar {
  activeLevel: number;
  changeLevel: (value: number) => void;
  chooseStation: (value: number, address: string) => void;
  changeInputValue: (value: string, name: string) => void;
  showNextLevel: () => void;
  getStations: () => void;
  getBusyTime: (id: number) => void;
  saveMonthsList: (data: IMonth[]) => void;
  saveCalendarData: (data: ICalendarCell[][]) => void;
  chooseMonth: (index: number) => void;
  getDate: (date: string) => void;
  sendForm: (date: ISendFormData) => void;
  saveDayIndex: (index: number) => void;
  saveTime: (time: string) => void;
  resetDateIndex: () => void;
  togglePopup: (value: boolean) => void;
  resetForm: () => void;
  saveTimeData: (timeData: ITime[]) => void;
}

export class ShinsCalendar extends React.Component<IProps> {
  public componentDidMount() {
    const { getStations, getBusyTime } = this.props;
    getStations();
    getBusyTime(1);
    this.getMonthsList();
    this.getCalendarData();
  }

  public onChangeInput = (value: string, name: string) => {
    this.props.changeInputValue(value, name);
  };

  public sendForm = () => {
    const { isButtonActive, userChoice, sendForm } = this.props;
    
    if(!isButtonActive) {
      return;
    }

    const { date, time, userName, userPhone, address } = userChoice;
    const [ year, month, day ] = date.split('-');
    const data = {
      address: address,
      date: `${day}.${month}.${year}`,
      time,
      name: userName,
      phone: userPhone.slice(1),
    };    

    const isValid = Object.keys(userChoice).reduce((accum: boolean, item: string ) => {
      if(item === 'userName') {
        if(userChoice[item].indexOf(' ') !== -1) {
          alert('Имя пользователя не может содержать пробелы');
          return false;
        }
      }

      if(accum) {
        return  Boolean(userChoice[item]);
      }
      return accum;
    }, true);

    isValid ?
      sendForm(data):
      alert('Заполните все данные');
  };

  public returnBack = (choiceLevel: number) => {
    this.props.changeLevel(choiceLevel - 1);
  };

  public checkLevel = (choiceLevel: number) => {
    this.props.changeLevel(choiceLevel);
  };

  public checkNextLevel = (value: number) => {
    this.props.changeLevel(value + 1);
  };

  public chooseStation = (id: number, address: string, fulltime: boolean, start_time: string, end_time: string) => {
    const { chooseStation, getBusyTime, saveTimeData } = this.props;
    const correctStartTime = start_time.slice(0,-3);
    const correctEndTime = end_time.slice(0,-3);

    const newTimeData:  any =  fulltime ? timeData : timeDataGenerator(correctStartTime, correctEndTime);

    
    chooseStation(id, address);
    getBusyTime(id);
    saveTimeData(newTimeData);
  };

  public getCurrentDate = (data: string, index: number) => {
    const { saveDayIndex, getDate } = this.props;
    saveDayIndex(index);
    getDate(data);
  };

  public chooseMonth = (activeMonth: number) => {
    const { chooseMonth, resetDateIndex } = this.props;
    chooseMonth(activeMonth);
    resetDateIndex();
  };

  public getMonthsList = () => {
    const { saveMonthsList } = this.props;
    const currentDate = new Date();
    const getMonth = (i: number) => currentDate.getMonth() + i;
    const monthArray = getArrFromNumber(3);

    const monthList = monthArray.map((monthNumber: number, i) => {
      const month = monthsList[getMonth(i)];
      const isActive = i === 0;

      return  {
        monthName: month,
        isActive: isActive
      };
    });
    
    saveMonthsList(monthList);
  };


  public getCalendarData = () => {
    const resultCalendarData = calendarDataFormatter();
    
    this.props.saveCalendarData(resultCalendarData);
  }
  
  public closePopUp = () => {
    const { togglePopup, resetForm, getBusyTime } = this.props;
    togglePopup(false);
    resetForm();
    getBusyTime(1);
  }

  public chooseTime = (time: string) => {
    this.props.saveTime(time);
  }
  
  public render() {
    const { activeLevel, stationsList, calendarData, monthList, levelConditions, userChoice,
      activeMonth, dateIndex, timeData, isShowPopUp } = this.props;
    const { address: activeStation, stationAddress, userName, userPhone, date, time } = userChoice;
    const {index } = dateIndex;
    const isActiveStationListTab = levelConditions[0].isActive;
    const isActiveCalendarTab = levelConditions[1].isActive;
    const isActiveFormTab = levelConditions[2].isActive;
    const [year, month, day] = date.split('-');
    
    const popUpData = {
      date: `${day}.${month}.${year}`,
      time,
      name: userName,
      phone: userPhone,
    }
    return (
      <Wrapper>
      { isShowPopUp && <PopUp address={stationAddress} recordingData={popUpData} onClick={this.closePopUp} /> }
      <Container>
        <Header headerCaption={headerCaption} headerText={headerText}/>
        <LevelScale activeLevel={activeLevel} checkLevel={this.checkLevel}/>

          {
            isActiveStationListTab && 
            <StationsList chooseStation={this.chooseStation} stationsList={stationsList} activeStation={activeStation} />
          }
          {
            isActiveCalendarTab && 
            <>
              <MonthList chooseMonth={this.chooseMonth} monthList={monthList} />
              <Calendar
                calendarData={calendarData[activeMonth]}
                getCurrentDate={this.getCurrentDate}
                chooseTime={this.chooseTime}
                dateIndex={dateIndex}
                timeList={timeData}
                activeDateIndex={index}
                selectedTime={time}
              />
            </>
          }
          {
            isActiveFormTab && 
              <SignUpForm
                nameValue={userName}
                phoneValue={userPhone}
                onChange={this.onChangeInput}
                sendForm={this.sendForm}
              />
          }
          {
            <ButtonList>
              <ButtonWrapper isMargin={activeLevel == 2}>
                <Button
                  height='50px'
                  onClick={() => this.returnBack(activeLevel)}
                  title={textConstants.btnBack}
                  width='150px'
                  isActive={true}
                  isShow={activeLevel !== 1}
                />
              </ButtonWrapper>
              <Button
                height='50px'
                onClick={() => this.checkNextLevel(activeLevel)}
                title={textConstants.btnContinue}
                width='150px'
                isActive={true}
                isShow={activeLevel !== 3}
              /> 
            </ButtonList>
          }
      </Container>
      <Footer/>
    </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  padding: 70px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.lightGray};
  @media (max-width: 750px) {
    width: calc(100% - 4px);
    padding: 0 0 40px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

const ButtonList = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div<IButtonWrapper>`
  margin-right: ${({ isMargin }) => isMargin && '40px'};
`;

