import React from "react";
import styled from "styled-components";

import { textConstants } from 'infrastructure';

const { footerDescription, linkToWebsite, linkToWebsiteDescription, email, emailValue, companyContacts } = textConstants;

export const Footer = () => (
  <Wrapper>
    <Text>
      {footerDescription}
    </Text>
    <StylezedText>
      <Span><Link href="https://shinaminsk.by/">{linkToWebsite}</Link> {linkToWebsiteDescription}</Span>
      <Span>{email} <Link href="mailto:office-kadr@tut.by">{emailValue}</Link></Span>
      <Span>{companyContacts}</Span>
    </StylezedText>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: justify;
`;

const Text = styled.p``;

const StylezedText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const Span = styled.span``;
const Link = styled.a``;
