import React from 'react';
import styled from 'styled-components';

import { LevelCircle } from 'atoms';
import { circleList } from './config';

interface ILevelScale {
  activeLevel: number;
  checkLevel: (activeLevel: number) => void;
}

export const LevelScale = (
  { activeLevel, checkLevel } : ILevelScale
) => (
  <Wrapper>
    {  
      circleList.map((props, i) =>
      <LevelCircle 
        {...props}
        onClick={checkLevel}
        isActive={activeLevel >= i + 1}
        key={i}
      >
      {i + 1}
      </LevelCircle>)
    }
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  padding: 40px 0;
  @media (max-width: 750px) {
    padding-top: 8px;
    padding-bottom: 20px;
  }
`;

