import { takeEvery, put, call } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { ACTIONS } from 'actionConstants';
import { Api } from '../../servises/Api';
import { saveStationsData, saveBusyTime, togglePopup, blockSendButton, getBusyTime } from 'reducers';
import { ISendFormData } from 'interfaces';

export function* mainWatcher() {
  yield takeEvery(ACTIONS.GET_STATIONS, loadStations);
  yield takeEvery(ACTIONS.GET_BUSY_TIME, loadBusyTime);
  yield takeEvery(ACTIONS.SEND_FORM, sendForm);
}

function* loadStations() {
  try {
    const { data } = yield call(() => Api.getStations());
    yield put(saveStationsData(data));
  } catch (error) {
    console.log('error', error);
  }
}

function* loadBusyTime({payload}: Action<number>) {
  try {
    if(payload) {
      const { data: { orders } } = yield call(() => Api.getBusyTime(payload));

      yield put(saveBusyTime(orders));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* sendForm({ payload }: Action<ISendFormData>) {
  try {
    if(payload) {
      yield put(blockSendButton(false));
      const { status } = yield call(() => Api.postUserData(payload));
      
      if(status === 200) {
        yield put(togglePopup(true));
        yield put(getBusyTime(1));
      }
      yield put(blockSendButton(true));
    }
  } catch (error) {
    const { status } = error.response;
    
    if(status === 400) {
      yield alert('Вы уже записаны на шиномонтаж');
      yield put(blockSendButton(true));
    }
    console.log('error', error);
    yield put(blockSendButton(true));
  }
}

