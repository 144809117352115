import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from 'context';

interface IForm {
  children: ReactNode;
}

export const Form = (
  { children } : IForm
) => (
  <Wrapper>{children}</Wrapper>
)

const Wrapper = styled.div`
  width: 435px;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 500px) {
    width: calc(100% - 4px);
  }
`;
