export const circleList = [
  {
    isBefore: true,
  },
  {
    isAfter: true,
    isBefore: true,
  },
  {
    isAfter: true,
  }
];
