export const colors = {
  white: '#fff',
  lightBlack: '#152d4b',
  oilBlack: '#1e2c4d',
  lightBlue: '#00b7f4',
  blue: '#00acf4',
  lightGray: '#e7eaed',
  silver: '#c3c3c3',
  gray: '#f2f2f2',
  black: '#333333',
  silverBlack: '#1b304c',
  lightSilver: '#fbfbfb',
  oceanBlue: '#2b7dfa',
  purple: 'rgba(10, 18, 78, 0.5)',
};
