import { ShinsCalendar as ShinsCalendarComponent } from './ShinsCalendar';

import { ILevelData, IBusyTime, IShinsCalendar, IUserChoice, ISendFormData, ICalendarCell, IMonth, ITimeCell, ITime } from 'interfaces';
import { connect } from 'react-redux';
import { chooseStation, changeLevel, changeInputValue, getStations,
  getBusyTime, saveMonthsList, saveCalendarData, chooseMonth, getDate, sendForm, saveDayIndex, saveTime,
  resetDateIndex, togglePopup, resetForm, saveTimeData } from 'reducers';
import { Dispatch } from 'redux';

const todayDate = new Date();

const getActiveLevel = (levelConditions : ILevelData[]) => 
  levelConditions.reduce((accum, {label, isActive}: ILevelData) => isActive ? label : accum, 0 );

const checkBusyTime = () => {
  const time = todayDate.getMinutes() < 30 ?
  `${todayDate.getHours() + 1}:00` :
  `${todayDate.getHours() + 1}:30`;
  return parseInt(time) < 10 ? `0${time}` : time;
}

const getTimeData = (timeData: ITimeCell[], busyTime: IBusyTime[], {date: currentDate}: IUserChoice) => {

  const correntMonth = todayDate.getMonth() + 1;
  const currentMonthTrueFormat = correntMonth < 10 ? `0${correntMonth}` : correntMonth;
  const today = `${todayDate.getDate()}:${currentMonthTrueFormat}`;


  const [ year, month, day ] = currentDate.split('-');

  const correctDay = Number(day) < 10 ? `0${day}` : day;
  const correctCurrentDate = `${year}-${month}-${correctDay}`;

  const currentBusyTime = busyTime.filter(({date}: IBusyTime) => date === correctCurrentDate);

  const busyTimeCount = currentBusyTime.reduce<{[key: string]: number}>((accum, {time}: IBusyTime) => (
    accum[time] ?
    {...accum, [time]: ++accum[time]} :
    {...accum, [time]: 1}
  ), {});

  const doubleBusyTime = Object.keys(busyTimeCount).filter((item: string) => busyTimeCount[item] >= 2);

  if(today === (`${day}:${month}`)) {
    
    const { timeDataWithVacancy } = timeData.reduce((accum: {timeDataWithVacancy: ITimeCell[], pastTime: boolean}, elem: ITimeCell ) => {
      const { time } = elem;
      const vacantTime = doubleBusyTime.find((busyTime: string) => busyTime.slice(0,5) ===  time);

      if(accum.pastTime && (elem.time !== checkBusyTime())) {
        accum.timeDataWithVacancy.push({...elem, isVacant: false })
        return accum;
      }
      accum.timeDataWithVacancy.push({...elem, isVacant: Boolean(!vacantTime)});

      return {
        ...accum,
        pastTime: false,
        timeDataWithVacancy: accum.timeDataWithVacancy,
      }

    }, {timeDataWithVacancy: [], pastTime: true})

    return timeDataWithVacancy;
  }

  return timeData.map((elem: ITimeCell) => {
    const { time } = elem;
    const vacantTime = doubleBusyTime.find((busyTime: string) => busyTime.slice(0,5) ===  time);
    return {...elem, isVacant: Boolean(!vacantTime)};
  })
}

const mapStateToProps = ({
  levelConditions,
  stationsList,
  calendarData,
  monthList,
  userChoice,
  activeMonth,
  dateIndex,
  busyTime,
  timeData,
  isShowPopUp,
  isButtonActive,
}: IShinsCalendar) => ({
  levelConditions,
  stationsList,
  calendarData,
  monthList,
  userChoice,
  activeLevel: getActiveLevel(levelConditions),
  activeMonth,
  dateIndex,
  busyTime,
  timeData: getTimeData(timeData, busyTime, userChoice, ),
  isShowPopUp,
  isButtonActive,
});

const mapDispatchProps = (dispatch: Dispatch) => ({
  changeLevel: (level: number) => dispatch(changeLevel(level)),
  chooseStation: (data: number, address: string) => dispatch(chooseStation({data, address})),
  changeInputValue: (value: string, name: string) => dispatch(changeInputValue({value, name})),
  getStations: () => dispatch(getStations()),
  getBusyTime: (id: number) => dispatch(getBusyTime(id)),
  saveMonthsList: (list: IMonth[]) => dispatch(saveMonthsList(list)),
  saveCalendarData: (data: ICalendarCell[][]) => dispatch(saveCalendarData(data)),
  chooseMonth: (index: number) => dispatch(chooseMonth(index)),
  getDate: (data: string) => dispatch(getDate(data)),
  sendForm: (data: ISendFormData) => dispatch(sendForm(data)),
  saveDayIndex: (index: number) => dispatch(saveDayIndex(index)),
  saveTime: (time: string) => dispatch(saveTime(time)),
  resetDateIndex: () => dispatch(resetDateIndex()),
  togglePopup: (value: boolean) => dispatch(togglePopup(value)),
  resetForm: () => dispatch(resetForm()),
  saveTimeData: (timeData: ITime[]) => dispatch(saveTimeData(timeData)),
});

export const ShinsCalendar = connect(
  mapStateToProps,
  mapDispatchProps
)(ShinsCalendarComponent);

