export const ACTIONS = {
  CHANGE_LEVEL: 'CHANGE_LEVEL',
  MOVE_TO_NEXT_LEVEL: 'MOVE_TO_NEXT_LEVEL',
  RETURN_BACK: 'RETURN_BACK',
  CHOOSE_STATION: 'CHOOSE_STATION',
  SHOW_NEXT_LEVEL: 'SHOW_NEXT_LEVEL',
  CHANGE_INPUT_VALUE: 'CHANGE_INPUT_VALUE',
  GET_STATIONS: 'GET_STATIONS',
  SAVE_STATIONS_DATA: 'SAVE_STATIONS_DATA',
  GET_BUSY_TIME: 'GET_BUSY_TIME',
  SAVE_BUSY_TIME: 'SAVE_BUSY_TIME',
  SAVE_MONTHS_LIST: 'SAVE_MONTHS_LIST',
  SAVE_CALENDAR_DATA: 'SAVE_CALENDAR_DATA',
  CHOOSE_MONTH: 'CHOOSE_MONTH',
  GET_DATE: 'GET_DATE',
  SEND_FORM: 'SEND_FORM',
  SAVE_DAY_INDEX: 'SAVE_DAY_INDEX',
  SAVE_TIME: 'SAVE_TIME',
  RESET_DATE_INDEX: 'RESET_DATE_INDEX',
  TOGGLE_POPUP: 'TOGGLE_POPUP',
  RESET_FORM: 'RESET_FORM',
  RESET_STATION_ADDRESS: 'RESET_STATION_ADDRESS',
  BLOCK_SEND_BUTTON: 'BLOCK_SEND_BUTTON',
  SEVE_TIME_DATA: 'SEVE_TIME_DATA',
}

