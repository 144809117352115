export const textConstants = {
  headerText: 'онлайн запись',
  headerCaption: 'Выберите удобные для вас шиномонтаж, дату и время!',
  btnContinue: 'Продолжить',
  btnBack: 'Назад',
  btnSignUp: 'Записаться',
  enterYourName: 'Введите Ваше имя',
  informationText: 'Пожалуйста, введите ваши данные, чтобы наш менеджер смог с Вами связаться',
  popUpCongratulation: 'поздравляем',
  popUpCaption: 'Вы упешно записались в наш автосервис!',
  popUpAddressTitle: 'Ждем Вас по адресу:',
  phone: 'Телефон ',
  footerDescription: 'Внимание! Записаться на шиномонтаж также можно по номерам, указанным выше(смотрите адреса станций). При опоздании более чем на 5 минут площадка занимается следующим автомобилем и Вы обслуживаетесь в порядке "живой" очереди. Поэтому настоятельно просим приезжать за 5-10 минут до выбранного вами времени.',
  linkToWebsite: 'SHINAMINSK.BY',
  linkToWebsiteDescription: '/ All rights reserved ©',
  email: 'E-mail:',
  emailValue: 'office-kadr@tut.by',
  companyContacts: 'ЧПТУП "Техношинсервис", г. Минск, ул. Бурдейного, 55, к. 6/а, 220136, УНП 192173857, Режим работы: 8.00 - 18.00 (офис)',
};
